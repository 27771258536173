import React from 'react'
import { Helmet } from 'react-helmet'
import Style from './index.module.scss'
import { withPrefix } from 'gatsby'

const Content = (
  <>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>RECIPE NOTE</title>
      <link
        rel="icon"
        type="image/x-icon"
        href={`${withPrefix('/')}img/recipe-note-icon.png`}
      />
    </Helmet>
    <div style={{ maxWidth: '900px', margin: 'auto', padding: "0px 20px" }}>
      <h3>RECIPE NOTE</h3>
      <h4>レシピノート - 画像でレシピを簡単管理</h4>
      <div style={{ textAlign: 'center', margin: '32px' }}>
        <img
          src={`${withPrefix('/')}img/recipe-note-icon.png`}
          alt="icon"
          width="200px"
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://play.google.com/store/apps/details?id=xyz.makitsystem.recipe_note&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img
            alt="Google Play で手に入れよう"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png"
            width="256px"
          />
        </a>
      </div>
      <h4 style={{ marginTop: '32px', marginBottom: '16px' }}>
        ■ レシピの一括管理ができるアプリ
      </h4>
      <ul>
        <li>
          レシピサイト、レシピ本、手書きのノートなど、散らばったレシピを画像で一括管理！
        </li>
        <li>レシピを画像で保存するから、入力の手間はかからない！</li>
        <li>タグを駆使して自分だけのオリジナルレシピブックを作成しよう！</li>
      </ul>
      <div className={Style.portfolioRecipeNoteImgParent}>
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot1.png`}
          alt="レシピノートスクリーンショット1"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot2.png`}
          alt="レシピノートスクリーンショット2"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot3.png`}
          alt="レシピノートスクリーンショット3"
        />
        <img
          src={`${withPrefix('/')}img/recipe-note-screen-shot4.png`}
          alt="レシピノートスクリーンショット4"
        />
      </div>
    </div>
  </>
)

const Layout = () => (
  <div className={Style.markdownBody}>{Content}</div>
)

export default Layout
